import { FormikProps } from 'formik';

export interface LooseObject {
  [key: string]: any;
}

export type PolicyForm = {
  policyName: string;
  policyDescription: string;
  version: string;
  employeeType: string;
  effectiveFrom: string;
  isCollectiveAgreementLock: boolean;
  status?: string;
  type: string;
  isAutoAssign: boolean;
};

export type CompensationPlan = {
  salaryInterval: string;
  totalSalary: {
    percentageValue: string;
    fixedValue: string;
  };
  partsUnder: {
    percentageValue: string;
    fixedValue: string;
  };
  partsOver: {
    percentageValue: string;
    fixedValue: string;
  };
};
export type TypeAge = {
  min: number | null;
  max: number | null;
};

export type TypeCalculation = {
  x: string;
  y: string;
  value: number | null;
  age: TypeAge;
  indexAmount: TypeAge;
};

export type TypeOccupationPension = {
  isIntervalCompensation: boolean;
  percentageCompensation: string | any;
  fixedCompensation: number | null;
  isPremium: boolean;
  amountIndex: string;
  offsetCalculation: string;
  ageDefination: string;
  calculation: TypeCalculation[];
};

export type TypeOffset = {
  offset: number;
  effectiveFrom: string;
  isEditAllowed: boolean;
  isChecked: boolean;
};

export type TypeManualOffset = {
  isManualOffsetAllowed: boolean;
  isChecked: boolean;
  offset: number;
  effectiveFrom: string;
};
export type TypeIncreasedOffset = {
  offsetAddition: number;
  isChecked: boolean;
};
export type TypeSalaryChange = {
  isAllowed: boolean;
  fixedMonthlyOffset: TypeOffset;
  percentageOfSalaryOffset: TypeOffset;
  manualOffset: TypeManualOffset;
  compensationWithIncreasedOffset: TypeIncreasedOffset;
  isCheckedSepratePensionManagerPlans: boolean;
};
export type TypeMap = {
  name: string;
  code: number;
  platformName: string;
};

export type TypeSalaryData = {
  name: string;
  code: number;
  percentage: number;
  holidayDaysPerYear: number;
  holidayPayMultiple: number;
  mapsTo: TypeMap[];
  selected: boolean;
  _id: string;
  id?: string;
  includedInPension: boolean;
  unit: string;
  type: string;
};

export type TypeSalaryHolidayData = {
  salaryType: {
    id?: string;
    name: string;
    code?: number;
    percentage?: number;
    mapsTo?: any;
  };
  holidayPayMultiple: number;
};

export type TypeSalaryWithHolidays = {
  isEnable: boolean;
  setupName: string;
  holidayDaysPerYear: number;
  data: TypeSalaryHolidayData[];
};

export type SalaryType = {
  isTemplateCalculation: boolean;
  salaryTypes: TypeSalaryData[];
  salaryTypesWithHolidays: TypeSalaryWithHolidays[];
  // data: TypeSalaryData[];
};

export type TypePensionManager = {
  pensionManagerId: string;
  planName: string;
  fromOccupationalPension: {
    isInclude: boolean;
    isDefault: boolean;
    insuranceNumber?: string | number;
    customerNumber?: string | number;
  };
  fromSalaryChange: {
    isInclude: boolean;
    isDefault: boolean;
    insuranceNumber?: string | number;
    customerNumber?: string | number;
  };
};

export type TypePlans = {
  categoryName: string;
  planName: string;
  isChecked: boolean;
};

export type TypeInsurances = {
  insuranceId: string;
  plans: TypePlans[];
};

export type MetaEmployeePolicySettings = {
  pensionManager: {
    effectiveFrom: '';
  };
  insurance: {
    effectiveFrom: '';
  };
};

export type PolicySettings = {
  occupationalPension: TypeOccupationPension;
  salaryChange: TypeSalaryChange;
  salaryType: SalaryType;
  pensionManagers: TypePensionManager[];
  insurances: TypeInsurances[];
  pensionManagerConfig: {
    employeeRightToEditChecked: boolean;
  };
  insuranceConfig?: {
    payInsuranceParental: boolean;
    payInsuranceOtherLeave: boolean;
  };
  employeeMetaSettings?: MetaEmployeePolicySettings | null;
  specialConditions?: PolicySpecialCondition;
};

export type PolicySpecialCondition = {
  probationaryEmployement: PolicyProbationaryEmployement;
  parentalLeave: PolicyParentalLeave;
  otherLeave: PolicyOtherLeave;
};

export type PolicyProbationaryEmployement = {
  isChecked: boolean;
  isPremiumCalculation: boolean;
  isSalaryChange: boolean;
  isInsurances: boolean;
};

export type PolicyParentalLeave = {
  isChecked: boolean;
  isPremiumCalculation: boolean;
  isSalaryChange: boolean;
  isInsurances: boolean;
  maximumLength: number | null;
};

export type PolicyOtherLeave = {
  isChecked: boolean;
  isPremiumCalculation: boolean;
  isSalaryChange: boolean;
  isInsurances: boolean;
  maximumLength: number | null;
};

export type PensionPolicy = {
  _id?: string;
  policyName: string;
  policyDescription: string;
  version: string;
  type: string;
  effectiveFrom: string;
  employeeType: string;
  isCollectiveAgreementLock: boolean;
  isDeleted?: boolean;
  isLocked?: boolean;
  status: string;
  isAutoAssign: boolean;
  policySettings: PolicySettings;
};

export interface ValuesObject {
  percentageValue: string | number | undefined;
  fixedValue: string | number | undefined;
}

export type TypeMapsTo = {
  name: string;
  code: number;
  platformName: string;
};
export type TypeSetupData = {
  defaults?: [];
  _id?: string;
  code: number;
  createdAt?: string;
  includedInPension?: boolean;
  mapsTo: TypeMapsTo[];
  name: string;
  updatedAt?: string;
  englishName?: string;
  percentage: number;
  holidayDaysPerYear: number;
  holidayPayMultiple: number;
};

export type TypeSalarySetup = {
  isPensionable: boolean;
  data: TypeSetupData[];
};

export enum ActionMode {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  ARCHIVED = 'archived',
  SCHEDULED = 'scheduled',
}

export enum InsuranceType {
  HEALTH = 'healthInsurancePlan',
  OCCUPATIONAL = 'occupationalPensionInsurancePlan',
  OTHER = 'otherInsurancesPlan',
  INDIVIDUAL = 'individualInsurancesPlan',
  GROUP = 'groupInsurancePlan',
}

export enum InsuranceSpecialConditionMode {
  ABLE_BODIED = 'able-bodied',
  DISABLED = 'disabled',
}

export enum SalaryIntervalAmountIndexType {
  IBB = 'IBB',
  PBB = 'PBB',
}

export enum SalaryIntervalAgeType {
  FROM_YEAR = 'from_year',
  FROM_MONTH = 'from_month',
}

export enum SalaryIntervalOffserCalcType {
  PERCENT = '%',
}

export enum PensionPolicyMode {
  ADMIN_POLICY = 'admin_policy',
  COMPANY_POLICY = 'company_policy',
  EMPLOYEE_WITHIN_COMPANY = 'employee_within_company',
  PERSONAL_POLICY = 'personal_policy',
}

export enum PensionPolicyCategory {
  COMPANY_POLICY = 'companyPolicy',
  COLLECTIVE_AGREEMENT_POLICY = 'collectiveAggrementPolicy',
  CORRECTO_PROVIDED_POLICY = 'correctoProvidedPolicy',
  PERSONAL_POLICY = 'personalPolicy',
  NO_POLICY = 'noPolicy',
}

export enum PensionPolicyVariant {
  COMPANY = 'Company',
  COLLECTIVE_AGREEMENT = 'CollectiveAgreement',
  CORRECTO_PROVIDED = 'CorrectoProvided',
  PERSONAL = 'Personal',
}

export enum IncludedPlanInputType {
  CUSTOMER_NUMBER = 'customerNumber',
  INSURANCE_NUMBER = 'insuranceNumber',
}

export enum EmployeeSalaryChangeInputType {
  FIXED_MONTHLY_OFFSET = 'fixedOffset',
  MANUAL_OFFSET = 'oneTimeOffset',
  PERCENTAGE_OF_SALARY_OFFSET = 'percentageOfSalaryOffset',
}

export enum PensionPolicyStatus {
  IN_ACTIVE = 'InActive',
}

export type PensionPolicyFormGroup = FormikProps<PensionPolicy>;

export interface EmployeeSalaryChangeOffset {
  current: {
    offset: number;
    effectiveFrom: string;
    updatedAt: string;
  };
  next: {
    offset: number;
    effectiveFrom: string;
    updatedAt: string;
  };
  history: any[];
}
export interface EmployeeSalaryChange {
  fixedOffset: EmployeeSalaryChangeOffset;
  oneTimeOffset: EmployeeSalaryChangeOffset;
  percentageOfSalaryOffset: EmployeeSalaryChangeOffset;
}

export interface PreExistEmployeePolicy {
  id: string;
  type: PensionPolicyVariant;
  attachedAt?: string;
}

export interface EmployeePensionPolicy {
  currentPolicy?: { id: string; type: PensionPolicyVariant; attachedAt?: string } | null;
  id?: string;
  type?: PensionPolicyVariant;
  attachedAt?: string;
  effectiveFrom: string;
  advisoryInvite?: boolean;
  employeeId: string;
  insurances: TypeInsurances[];
  pensionManagers: TypePensionManager[];
  salaryChange: EmployeeSalaryChange;
  policySettings?: any;
}

export interface CommonPolicy {
  effectiveFrom: string;
  assignedEmployeePension?: any;
}

export interface EmployeePensionRequestInput {
  currentPolicy?: { id: string; type: PensionPolicyVariant; attachedAt?: string } | null;
  id: string;
  type: string;
  attachedAt: string;
  effectiveFrom: string;
  advisoryInvite?: boolean;
  employeeId: string;
  insurances: {
    effectiveFrom: string;
    insurancesList: TypeInsurances[];
  };
  pensionManagers: {
    effectiveFrom: string;
    plans: TypePensionManager[];
  };
  salaryChange: EmployeeSalaryChange;
  policySettings?: any;
}

export interface EmployeePensionSummary {
  _id: string;
  employeeId: string;
  current?: EmployeePensionSummaryItem;
  schedule?: EmployeePensionSummaryItem;
}

export interface EmployeeArchivedPension {
  id: string;
  employeeId: string;
  effectiveTill: string;
  originalDocumentId: string;
  current: EmployeePensionSummaryItem & { policyName: string };
  currentInternal?: EmployeePensionPolicy;
  policyMetaDetails?: PensionPolicy;
}

export interface EmployeePensionSummaryItem {
  effectiveFrom: string;
  id: string;
  type: PensionPolicyVariant;
  attachedAt: string;
  advisoryInvite?: boolean;
  salaryChange: EmployeeSalaryChange;
  pensionManagers: EmployeeManagerSummary;
  insurances: EmployeeInsuranceSummary;
}

export interface EmployeeManagerSummary {
  next: {
    effectiveFrom: string;
    plans: TypePensionManager[];
  };
  current?: {
    effectiveFrom: string;
    plans: TypePensionManager[];
  };
  history: any[];
}

export interface EmployeeInsuranceSummary {
  next: {
    effectiveFrom: string;
    insurancesList: TypeInsurances[];
  };
  current?: {
    effectiveFrom: string;
    insurancesList: TypeInsurances[];
  };
  history: any[];
}

export interface IPensionManagerPlan {
  hjertaPartner: any[];
  notHjertaPartner: any[];
}

export interface IReloadProps {
  isEditAction?: boolean;
  restoreResetMode?: boolean;
}

export interface IEmployeeInfoInput {
  paidHolidays: number;
}

export enum AgreementGroup {
  COLLECTIVE = 'isCollectiveAgreement',
  NON_COLLECTIVE = 'isNotCollectiveAgreement',
}

export enum EmploymentType {
  WHITE_COLLAR = 'White collar',
  BLUE_COLLAR = 'Blue collar',
}

export enum PensionPolicyTabKey {
  OCCUPATIONAL = 'occupationalPension',
  SALARY_CHANGE = 'salaryChange',
  SALARY_TYPE = 'salaryType',
  PENSION_MANAGER = 'pensionManagers',
  INSURANCE = 'insurances',
  SPECIAL_CONDITIONS = 'specialConditions',
}

export enum PolicyLeaveCategory {
  PROBATIONARY = 'probationaryEmployement',
  PARENTAL = 'parentalLeave',
  OTHER = 'otherLeave',
}

export const PENSION_POLICY_MOD_KEY = {
  SALARY_TYPE: {
    DEFAULT: 'policySettings.salaryType.salaryTypes',
    HOLIDAYS: 'policySettings.salaryType.salaryTypesWithHolidays',
  },
};
