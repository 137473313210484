import React, { useEffect, useRef, useState } from 'react';
import { Container, Tabs, Tab, Button, Spinner } from 'react-bootstrap';
import { Save, Trash2 } from 'react-feather';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  cancelEmployeeVaccationSchedule,
  getEmloyeeDetails,
  getEmployeePension,
  saveEmployeePension,
  updateEmployee,
  updateEmployeePension,
} from '../../../../api/service/company/employees/service';
import { CompanyEmployeeType, EmployeeVacationAllowance } from '../../../../api/service/company/employees/types';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import OverviewTab from '../../../../section/company/employee/OverviewTab';
import PensionTab from '../../../../section/PensionTab';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { deepClone, isEmpty, isEmptyVal, isNumeric, isObjectEmpty, toNumber } from '../../../../util/util';
import useContext from '../useContext';
import { useTranslation } from 'react-i18next';
import { LooseObject } from '../../../superAdmin/CompanyClients/types';
import classNames from 'classnames';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { getPolicyDetails } from '../../../../api/service/superAdmin/pension-policies/service';
import { PensionPolicyCategory } from '../../../superAdmin/PensionPolicy/types';
import PensionOffset from './PensionOffset';
import useTabChangeListener from '../../../../hooks/useTabChangeListener';
import { EmployeePensionRequest } from '../entity/EmployeePensionRequest';
import { EmployeePensionRecord } from '../entity/EmployeePensionRecord';
import { PensionTabKey } from '../types';
import usePensionSchedule from '../hooks/usePensionSchedule';
import usePensionTabMode from '../hooks/usePensionTabMode';
import useAssignEmployeePension from '../hooks/useAssignEmployeePension';
import useActionLoading from '../hooks/useActionLoading';
import useArchivedPension from '../hooks/useArchivedPension';
import ErrorAlert from '../../../../components/ErrorAlert';
import usePensionMode from '../hooks/usePensionMode';
import useEmployeePensionObserver from '../hooks/useEmployeePensionObserver';
import useEmployeeOverviewObserver from '../hooks/useEmployeeOverviewObserver';
import CancelButton from '../../../../components/base/CancelButton';
import { ReactComponent as ScheduleIcon } from '../../../../assets/img/scheduled.svg';

export type TabDataType = {
  employee: CompanyEmployeeType | null | LooseObject;
  tabKey?: string | null;
  reset?: boolean;
  setReset?: React.Dispatch<React.SetStateAction<boolean>>;
  policy?: any;
  pensionDataset?: any;
  insuranceDataset?: any;
};
let _employeeDetails: any = {};

function EmployeeDetails() {
  const { setErrors, errors, isInvalid } = useValidationError();
  const { isFormModified: isEmployeePensionFormModified, revertChanges } = useEmployeePensionObserver();
  const { isFormModified: isEmployeeOverviewFormModified, revertChanges: revertEmployeeOverviewChanges } =
    useEmployeeOverviewObserver();
  const {
    pensionCompanies,
    salaryChange,
    policies,
    effectiveFrom,
    formGroup,
    replaceEmployeeDetails,
    employee,
    selectedPolicyType,
    advisoryInvite,
    vacation,
    replaceVacationDetails,
    setSelectedScheduledEmployeePension,
    setSelectedScheduledPensionPolicy,
    pensionTabKey,
    employeePensionSummary,
    setEmployeePensionSummary,
    parentalInfo,
    replaceParentalLeave,
    setEmployeeRecord,
  } = useContext();

  const pensionMode = usePensionMode();
  const { onCancelSchedule } = usePensionSchedule();
  const { fetchAllArchivedPensionList } = useArchivedPension();
  const { hasDisabledPolicy } = useAssignEmployeePension();
  const pensionTabMode = usePensionTabMode();
  const [_policy, _setpolicy] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [tabKey, setTabKey] = useState<any | undefined>('overview');
  const params = useParams();
  const [errorMsg, setErrorMsg] = useState('');
  const tabContentRef = useRef(null);
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Employees',
      href: '/employees',
    },
    { name: `${employee?.fullName}`, translate: false },
  ];
  const employeeID = params.id;
  const hasPolicy = !isEmpty(_policy);
  const [reset, setReset] = useState(false);
  const actionLoading = useActionLoading();
  const isError = isEmpty(pensionCompanies) || isEmpty(salaryChange) || effectiveFrom === '';
  const cleanedSalaryChange = _.pickBy(salaryChange, (i) => i !== undefined);
  const isSalaryChangesUpdate = _.isEqual(_policy?.['salaryChange'], cleanedSalaryChange);
  const iseffectiveFromChange = _.isEqual(_policy?.['effectiveFrom'], effectiveFrom);
  const isPensionCompaniesChange = _.isEqual(_policy?.['pensionCompanies'], pensionCompanies);
  const isPolicyChange = _.isEqual(_policy?.['currentPolicy'], policies);
  const [pensionDataset /* setPensionDataset */] = useState(null);
  const [insuranceDataset /* setInsuranceDataset */] = useState(null);
  const { t } = useTranslation();
  const hasChanges = !isSalaryChangesUpdate || !iseffectiveFromChange || !isPensionCompaniesChange || !isPolicyChange;
  /* const headerRows = [
    { title: 'Reported Pension', name: 'reportedPension' },
    { title: 'Reported Salary Changes', name: 'reportedSalaryChanges' },
  ]; */
  // const yearRange = ['2021-10', '2022-12'];

  const hasValidateEmployee = () => {
    if (!isNumeric(vacation?.paidHolidays)) {
      return false;
    }

    if (isEmptyVal(vacation?.effectiveFrom)) {
      return false;
    }

    return true;
  };

  const getEmployee = async () => {
    try {
      setIsBusy(true);
      const employer = await getEmloyeeDetails(params.id);
      _employeeDetails = structuredClone(employer);

      transformEmployeeEntity(employer);

      setEmployeeRecord(deepClone(employer));
      replaceEmployeeDetails({ ...employer });
      replaceVacationDetails(employer.vacation);
      replaceParentalLeave(employer.parentalInfo);

      await fetchEmployeePensionPolicy();
      //await fetchPlans();
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const cancelScheduledVaccation = async () => {
    try {
      /* const _payload = {
        paidHolidays: !isEmptyVal(vacation?.paidHolidays) ? toNumber(vacation?.paidHolidays) : null,
        effectiveFrom: vacation?.effectiveFrom || null,
      }; */

      const employeeResponse = await cancelEmployeeVaccationSchedule(params.id);

      transformEmployeeEntity(employeeResponse);

      setEmployeeRecord(deepClone(employeeResponse));
      replaceEmployeeDetails(deepClone(employeeResponse));
      replaceVacationDetails(employeeResponse.vacation);
      replaceParentalLeave(employeeResponse.parentalInfo);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    }
  };

  const transformEmployeeEntity = (_employee) => {
    if (_employee.vacation) {
      _employee.vacationAllowance = deepClone(_employee.vacation) as EmployeeVacationAllowance;
      _employeeDetails.vacationAllowance = deepClone(_employeeDetails.vacation) as EmployeeVacationAllowance;

      if (_employee.vacation.next?.effectiveFrom) {
        _employee.vacation = deepClone(_employee.vacation.next);
        _employeeDetails.vacation = deepClone(_employeeDetails.vacation.next);
      } else if (!_employee.vacation.next?.effectiveFrom && _employee.vacation.current?.effectiveFrom) {
        _employee.vacation = deepClone(_employee.vacation.current);
        _employeeDetails.vacation = deepClone(_employeeDetails.vacation.current);
      }
    } else {
      _employee.vacation = null;
      _employee.vacationAllowance = null;
      _employeeDetails.vacation = null;
      _employeeDetails.vacationAllowance = null;
    }
  };

  const onSaveChange = async () => {
    const payload: any = {
      employeeId: params.id,
      // currentPolicy: selectedPolicyType === PensionPolicyCategory.NO_POLICY ? null : policies,
      effectiveFrom,
      ...EmployeePensionRequest.toModel({
        currentPolicy: selectedPolicyType === PensionPolicyCategory.NO_POLICY ? null : policies,
        employeePolicySettings: formGroup.values.policySettings,
        selectedPolicyType: selectedPolicyType,
      }),
    };

    if (selectedPolicyType === PensionPolicyCategory.NO_POLICY) {
      payload.advisoryInvite = advisoryInvite;
    }

    try {
      setIsSaving(true);
      hasPolicy
        ? await updateEmployeePension(employeePensionSummary!._id, payload)
        : await saveEmployeePension(payload);

      await fetchEmployeePensionPolicy(true);
      // window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsSaving(false);
    }
  };

  /* const fetchPlans = async () => {
    const _managerResponse = await getPensionManagers({ isDraft: false, isCollectiveAgreement: false });
    const _insuranceResponse = await getInsurances({ isDraft: false, isCollectiveAgreement: false });

    setInsuranceDataset(_insuranceResponse);
    setPensionDataset(_managerResponse);
  }; */

  const fetchEmployeePensionPolicy = async (forceRefresh = false) => {
    try {
      if (forceRefresh) {
        actionLoading.toggle('loadEmployeePension', true);
      }

      let employeePolicySummary = await getEmployeePension(params.id);

      setEmployeePensionSummary(deepClone(employeePolicySummary));

      pensionTabMode.activateTab(employeePolicySummary);

      if (employeePolicySummary) {
        if (employeePolicySummary.current) {
          const employeeAssignedPolicy = EmployeePensionRecord.toPolicySettings(
            employeePolicySummary.current,
            employeePolicySummary.employeeId,
          );
          // let choosenPolicType = PensionPolicyCategory.NO_POLICY;
          let policy = null;

          if (employeeAssignedPolicy?.id) {
            policy = await getPolicyDetails(employeeAssignedPolicy!.id);
            // choosenPolicType = findPolicyType(employeeAssignedPolicy!.type!)!;
          }

          _setpolicy(policy || {});
          //! [TODO]: Need to remove _setpolicy state which will after load then calls employeeRevision changes

          /* setTimeout(() => {
          setSelectedPensionPolicy(policy);
          setSelectedEmployeePension(employeeAssignedPolicy);
          updatePolicyType(choosenPolicType);

          if (employeeAssignedPolicy!.policySettings) {
            setEmployeePolicyRevision({
              ...employeeAssignedPolicy,
            });
          }
          }, 5); */
        } else {
          _setpolicy({});
          //! [TODO]: Need to remove _setpolicy state which will after load then calls employeeRevision changes

          /* setSelectedPensionPolicy(null);
          setSelectedEmployeePension(null);
          setEmployeePolicyRevision(null);
          updatePolicyType(null);
          setAdvisoryInvite(false); */
        }

        if (employeePolicySummary.schedule) {
          const employeeScheduledPolicy = EmployeePensionRecord.toPolicySettings(
            employeePolicySummary.schedule,
            employeePolicySummary.employeeId,
          );

          let _policyDetails = null;
          let _employeePensionDetails = { ...employeeScheduledPolicy };

          if (employeeScheduledPolicy?.id) {
            _policyDetails = await getPolicyDetails(employeeScheduledPolicy!.id);
          }

          setSelectedScheduledEmployeePension(_employeePensionDetails);
          setSelectedScheduledPensionPolicy(_policyDetails);
        } else {
          setSelectedScheduledEmployeePension(null);
          setSelectedScheduledPensionPolicy(null);
        }
      } else {
        _setpolicy({});
        //! [TODO]: Need to remove _setpolicy state which will after load then calls employeeRevision changes

        /* setSelectedPensionPolicy(null);
        setSelectedEmployeePension(null);
        setEmployeePolicyRevision(null);
        updatePolicyType(null);
        setAdvisoryInvite(false); */

        setSelectedScheduledEmployeePension(null);
        setSelectedScheduledPensionPolicy(null);
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (forceRefresh) {
        actionLoading.toggle('loadEmployeePension', false);
      }
    }
  };

  const onConfirmCancelSchedule = () => {
    onCancelSchedule(() => fetchEmployeePensionPolicy(true));
  };

  const onUpdateEmployee = async () => {
    try {
      const data = {
        phoneNumberWork: employee?.phoneNumberWork,
        phoneNumberPrivate: employee?.phoneNumberPrivate,
        email: employee?.email,
        personnelType: employee?.personnelType,
        costCenter: employee?.costCenter,
        scopeOfWork: !isEmptyVal(employee?.scopeOfWork) ? toNumber(employee?.scopeOfWork) : null,
        vacation: {
          paidHolidays: !isEmptyVal(vacation?.paidHolidays) ? toNumber(vacation?.paidHolidays) : null,
          effectiveFrom: vacation?.effectiveFrom || null,
        },
        parentalInfo,
      };
      const errors = await formGroup.validateForm();

      if (!isObjectEmpty(errors)) {
        setErrors(formGroup.errors);

        return;
      }

      setErrors({});
      setIsSaving(true);

      const employeeResponse = await updateEmployee(params.id, data);
      const employer = await getEmloyeeDetails(params.id);
      _employeeDetails = structuredClone(employer);

      transformEmployeeEntity(employer);

      setEmployeeRecord(deepClone(employer));
      replaceEmployeeDetails({ ...employeeResponse, ...employer });
      replaceVacationDetails(employer.vacation);
      replaceParentalLeave(employer.parentalInfo);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const validatePensionEffectiveDate = () => {
    const assignedSalaryChange = formGroup.values?.policySettings?.salaryChange;
    const _employeeMetaSettings = formGroup.values?.policySettings?.employeeMetaSettings;

    const fixedEffectiveDate = assignedSalaryChange?.fixedOffset?.effectiveFrom;
    const manualEffectiveDate = assignedSalaryChange?.oneTimeOffset?.effectiveFrom;
    const percentageSalaryEffectiveDate = assignedSalaryChange?.percentageOfSalaryOffset?.effectiveFrom;
    const pensionManagerEffectiveDate = _employeeMetaSettings?.pensionManager?.effectiveFrom;
    const insuranceEffectiveDate = _employeeMetaSettings?.insurance?.effectiveFrom;

    return (
      !isEmptyVal(fixedEffectiveDate) &&
      !isEmptyVal(manualEffectiveDate) &&
      !isEmptyVal(percentageSalaryEffectiveDate) &&
      !isEmptyVal(pensionManagerEffectiveDate) &&
      !isEmptyVal(insuranceEffectiveDate)
    );
  };

  const onCancelChanges = () => {
    revertChanges();
  };

  useEffect(() => {
    if (params.policyId) {
      setTabKey('pension');
    }
  }, [params]);

  useEffect(() => {
    getEmployee();
    fetchAllArchivedPensionList();
  }, []);

  useTabChangeListener(tabKey);

  const disabledButton =
    isError && selectedPolicyType !== 'noPolicy'
      ? isError
      : (selectedPolicyType !== 'noPolicy' && !policies && !policies?.['id']) ||
        isSaving ||
        (hasPolicy ? !hasChanges : false);

  return (
    <Container fluid className="p-0">
      {isBusy ? (
        <Spinner />
      ) : errorMsg ? (
        errorMsg
      ) : (
        <>
          <Breadcrumb navItem={breadcrumb} />
          <div className="d-flex justify-content-between">
            <PagesHeader headingClass="fs-h1" pageTitle={`${employee?.fullName}`} translate={false} />
            {tabKey === 'pension' && (
              <div className="cr-form-row">
                {pensionTabKey === PensionTabKey.CURRENT && (
                  <Button
                    disabled={disabledButton}
                    onClick={() => setReset(true)}
                    className={classNames('d-none', 'app-btn-secondary me-2', disabledButton ? 'unique-disable' : '')}
                  >
                    {t('reset_changes')}
                  </Button>
                )}
                {pensionTabKey === PensionTabKey.CURRENT && pensionMode.hasEditableCurrentPolicy() && (
                  <>
                    {isEmployeePensionFormModified && <CancelButton onCancel={onCancelChanges} />}
                    <Button
                      disabled={
                        hasDisabledPolicy() || !isEmployeePensionFormModified || !validatePensionEffectiveDate()
                      }
                      onClick={onSaveChange}
                      className="app-btn-primary app-btn-icon"
                    >
                      {isSaving ? (
                        <Spinner className="icon-gap-right" size="sm" />
                      ) : (
                        <ScheduleIcon className="icon-gap-right icon-white" />
                      )}{' '}
                      {/* {hasPolicy ? t('update') : t('save_changes')} */}
                      {t('schedule_changes')}
                    </Button>
                  </>
                )}
                {pensionMode.hasOnlyScheduled() && (
                  <Button onClick={onConfirmCancelSchedule} className="app-btn-danger">
                    {actionLoading.cancelSchedule ? (
                      <Spinner className="icon-gap-right" size="sm" />
                    ) : (
                      <Trash2 className="icon-gap-right" />
                    )}{' '}
                    {t('cancel_scheduled_update')}
                  </Button>
                )}
              </div>
            )}
            {tabKey === 'overview' && (
              <div className="cr-form-row">
                {isEmployeeOverviewFormModified && <CancelButton onCancel={revertEmployeeOverviewChanges} />}
                <Button
                  onClick={onUpdateEmployee}
                  className="app-btn-primary"
                  disabled={!isEmployeeOverviewFormModified || !hasValidateEmployee()}
                >
                  {isSaving ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}{' '}
                  {t('save_changes')}
                </Button>
              </div>
            )}
          </div>
          <ValidationAlert show={isInvalid} errors={errors} />
          {tabKey === 'overview' && (
            <>
              <ErrorAlert
                show={employee?.vacationPolicyIsValid === false || false}
                msg={t('EMPLOYEE_PENSION.paid_vacation_days_match_policy')!}
              />
              <ErrorAlert
                show={!employee?.vacation ? true : false}
                msg={t('EMPLOYEE_PENSION.vacation_days_missing')!}
              />
            </>
          )}
          {tabKey === 'pension' && (
            <>
              <ErrorAlert
                show={employee?.vacationPolicyIsValid === false || false}
                msg={t('EMPLOYEE_PENSION.paid_vacation_days_match_policy')!}
              />
              <ErrorAlert
                show={!employeePensionSummary ? true : false}
                msg={t('PENSION_POLICY.no_employee_policy_attached')!}
              />
            </>
          )}
          <div ref={tabContentRef}>
            <Tabs
              activeKey={tabKey}
              id="crt-employee-policy-tab-container"
              onSelect={(val) => setTabKey(val)}
              defaultActiveKey="overview"
            >
              <Tab
                eventKey="overview"
                title={t('overview')}
                tabClassName={classNames({
                  'invalid-tab':
                    !employee?.vacation || employee?.vacationPolicyIsValid === false || !isObjectEmpty(errors),
                })}
              >
                <OverviewTab cancelVaccationSchedule={cancelScheduledVaccation} />
              </Tab>
              <Tab
                eventKey="pension"
                title={t('pension_policy')}
                tabClassName={classNames({
                  'invalid-tab': !employeePensionSummary || employee?.vacationPolicyIsValid === false,
                })}
              >
                <PensionTab
                  policy={_policy}
                  setReset={setReset}
                  reset={reset}
                  employee={employee}
                  tabKey={tabKey}
                  pensionDataset={pensionDataset}
                  insuranceDataset={insuranceDataset}
                />
              </Tab>
              <Tab eventKey="pension_offset" title={t('pension_offset')}>
                <PensionOffset employeeID={employeeID!} />
                {/* <CardComponent>
                  <MonthlyStepper
                    headerTitle="Pension company"
                    datasource={PENSION_CORRECTIONS}
                    yearRange={yearRange}
                  />
                </CardComponent> */}
              </Tab>
              {/* <Tab eventKey="reports" title={t('reports')}>
                <ReportTab />
              </Tab> */}
            </Tabs>
          </div>
        </>
      )}
    </Container>
  );
}

export default EmployeeDetails;
