import { EMPLOYEE_SALARY_CHANGE_MAPPED_INPUT } from '../../../superAdmin/PensionPolicy/constants';
import {
  EmployeePensionPolicy,
  EmployeePensionSummaryItem,
  EmployeeSalaryChangeInputType,
  MetaEmployeePolicySettings,
  PolicySettings,
} from '../../../superAdmin/PensionPolicy/types';

export class EmployeePensionRecord {
  static toPolicySettings(entity: EmployeePensionSummaryItem, employeeId: string): EmployeePensionPolicy | null {
    if (!entity) {
      return null;
    }

    const _insurances = entity.insurances?.next || entity.insurances?.current;
    const _pensionManagers = entity.pensionManagers?.next || entity.pensionManagers?.current;

    const policySettings = {
      pensionManagers: _pensionManagers?.plans || [],
      insurances: _insurances?.insurancesList || [],
      salaryChange: EMPLOYEE_SALARY_CHANGE_MAPPED_INPUT.reduce((prev, cur) => {
        if (entity.salaryChange[cur.key]) {
          if (cur.key === EmployeeSalaryChangeInputType.MANUAL_OFFSET) {
            const oneTimeOffsetEntries = entity.salaryChange[cur.key] as unknown as any[] | null;
            const latestEntry = oneTimeOffsetEntries?.length
              ? oneTimeOffsetEntries[oneTimeOffsetEntries.length - 1]
              : null;

            prev[cur.mapTo] = latestEntry;
          } else {
            prev[cur.mapTo] = entity.salaryChange[cur.key].next || entity.salaryChange[cur.key].current;
          }
        }

        return prev;
      }, {}),
      employeeMetaSettings: {
        insurance: {
          effectiveFrom: _insurances?.effectiveFrom || '',
        },
        pensionManager: {
          effectiveFrom: _pensionManagers?.effectiveFrom || '',
        },
      } as MetaEmployeePolicySettings,
    } as PolicySettings;

    const model = {
      currentPolicy: { id: entity.id, type: entity.type, attachedAt: entity.attachedAt },
      id: entity.id,
      type: entity.type,
      attachedAt: entity.attachedAt,
      effectiveFrom: entity.effectiveFrom,
      advisoryInvite: entity.advisoryInvite,
      employeeId,
      insurances: policySettings.insurances,
      pensionManagers: policySettings.pensionManagers,
      salaryChange: entity.salaryChange,
      policySettings,
    };

    return model;
  }
}
