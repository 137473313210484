import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuth from '../../../../../hooks/useAuth';
import {
  IReportFilterParams,
  IEmployeeSalaryReportResponse,
  ComparisonSeriesReportType,
  FileExportType,
  ReportNameMap,
} from '../../../../../types/report';
import { Calendar } from '../../../../../util/calendar';
import { numberFormat } from '../../../../../util/util';
import { CUSTOM_REPORT_TYPES } from '../../constants';
import { exportCustomizableReport } from '../../../../../api/service/company/reports/service';

const useCustomizableTableReport = (
  requestFn: (param: IReportFilterParams) => Promise<IEmployeeSalaryReportResponse>,
) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  // report table layout
  const [reportHeaders, setReportHeaders] = useState<any>();
  const [reportRecords, setReportRecords] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState(Calendar.currentYear);
  const years = Calendar.getYearBoundary(5);

  const getCalculatedSalaryChangeReports = async () => {
    try {
      const reportDetails: IEmployeeSalaryReportResponse = await requestFn({
        duration: {
          from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
          to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: selectedYear,
      });

      processReportData({
        data: reportDetails.salariesByDate,
        employees: reportDetails.employees,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const processReportData = (reportResult) => {
    const formattedFieldData = reportResult?.data?.map((employee) => {
      return employee.employeeSalaries.map((data) => {
        const value = {
          totalPayable: data.totalPayable.value,
        };

        CUSTOM_REPORT_TYPES.forEach((report) => {
          value[report.field] = data?.[report.field]?.value;
        });

        return { [`field` + data.employee.id]: value };
      });
    });
    const updatedFieldData = formattedFieldData.map((el, idx) => {
      return Object.assign([], el);
    });
    const records = reportResult.data.map((employee, index) => {
      return {
        date: moment(employee.date).format('MMM-YY'),
        total: employee.total,
        ...updatedFieldData[index].reduce((current, next) => {
          return { ...current, ...next };
        }),
      };
    });

    setReportRecords(records);
    initializeHeaders(reportResult.employees);
  };

  const initializeHeaders = (employees: IEmployeeSalaryReportResponse['employees']) => {
    const columns = [
      {
        text: 'Month',
        dataField: 'date',
        formatter: (cell) => <span className={classNames('font-weight-bold', 'theme-text')}>{t(cell || '')}</span>,
        footer: 'Total',
        footerClasses: 'font-bold theme-text title-header-col',
        headerClasses: 'fixed-col title-header-col title-header-col--extended',
        classes: 'fixed-col-content',
      },
      {
        text: 'Total / month',
        dataField: 'total',
        classes: 'fixed-col-content number-col',
        footer: (columnData) => (
          <span className="cell-content">
            {numberFormat(columnData.reduce((acc, item) => acc + item?.totalPayable || 0, 0))}
          </span>
        ),
        formatter: (cell) => {
          return <span className="cell-content">{numberFormat(cell?.totalPayable || 0) || ''}</span>;
        },
        footerClasses: 'font-bold theme-text number-col',
        headerClasses: 'fixed-col series-header-col',
      },
    ].concat(
      employees.map((employee) => {
        return {
          dataField: `field${employee.id}`,
          text: employee as any,
          headerFormatter: formatEmployeeHeader,
          headerClasses: 'col-head fluid-col series-header-col',
          formatter: (cell) => {
            return <span className="cell-content">{numberFormat(cell?.totalPayable || 0) || ''}</span>;
          },
          footer: (columnData) => (
            <span className="cell-content">
              {numberFormat(columnData.reduce((acc, item) => acc + item?.totalPayable || 0, 0))}
            </span>
          ),
          classes: 'fluid-col-content number-col',
          footerClasses: 'font-bold theme-text number-col',
        };
      }),
    );
    setReportHeaders(columns);
  };

  const formatEmployeeHeader = (column) => {
    return (
      <div className="col-title">
        {column.text.fullName}
        <div className="col-subtext">{column.text.personalNumber}</div>
      </div>
    );
  };

  const printReport = () => {
    window.print();
  };
  const setYearFilter = (value) => {
    setSelectedYear(value);
  };

  const exportCSV = async (fileType: FileExportType = FileExportType.XLSX) => {
    try {
      setIsExporting(true);
      const reportDetails = await exportCustomizableReport({
        duration: {
          from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
          to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: selectedYear,
      });

      const url = window.URL.createObjectURL(new Blob([reportDetails]));
      const filePrefix = ReportNameMap['customizable'];
      const fileSuffix = Calendar.instance().format('DDMMYYYYHHmmss');
      const fileName = `${filePrefix}-${fileSuffix}.${fileType}`;

      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
    // }
  };

  useEffect(() => {
    getCalculatedSalaryChangeReports();
  }, [selectedYear]);

  return {
    reportHeaders,
    reportRecords,
    isLoading,
    years,
    isExporting,
    printReport,
    setYearFilter,
    exportCSV,
  };
};

export default useCustomizableTableReport;
